/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useMemo } from 'react';
import { useStore } from '@/utils/store';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';

import reRouteTo from '@/utils/api/sessionPass';
import { getPlatform, openZendesk } from '@/utils/functions/common';
import ServicePlatformChoice from '@/components/common/ServicePlatformChoice/ServicePlatformChoice';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';
import usePortal from '@/components/common/Modal/Portal';
import TgIcon from './icons/TgIcon';
import LiIcon from './icons/LiIcon';
import IgIcon from './icons/IgIcon';
import MdIcon from './icons/MdIcon';
import LtIcon from './icons/LtIcon';
import TwIcon from './icons/TwIcon';
import FbIcon from './icons/FbIcon';
import styles from './MainFooter.module.scss';
import LogoMarquee from './LogoMarquee';
import ThreadsIcon from './icons/ThreadsIcon';
import CmcIcon from './icons/CmcIcon';

const tgUrl = {
  zh: 'https://t.me/BitopExchange_official/1',
  en: 'https://t.me/BitopExchange_official/132790',
};

function Footer() {
  const router = useRouter();
  const { t, i18n } = useTranslation('common', { keyPrefix: 'footer' });
  const { t: t2 } = useTranslation('common');
  const { user } = useStore().auth;
  const { Portal, PortalOpen, PortalClose } = usePortal({ closeOnOutSide: true });
  const { phrase, referral } = useH5StringBuilder();

  const renderSocialButtons = useMemo(() => {
    const socialList = [
      {
        name: 'telegram',
        icon: TgIcon,
        url: tgUrl[i18n.language],
      },
      {
        name: 'CoinMarketCap',
        icon: CmcIcon,
        url: 'https://coinmarketcap.com/community/profile/Bitop_Exchange/',
      },
      {
        name: 'linkedin',
        icon: LiIcon,
        url: 'https://www.linkedin.com/company/bitop-cryptocurrency-exchange/about/',
      },
      {
        name: 'instagram',
        icon: IgIcon,
        url: 'https://www.instagram.com/bitop_exchange/',
      },
      {
        name: 'Threads',
        icon: ThreadsIcon,
        url: 'https://www.threads.net/@bitop_exchange/',
      },
      // {
      //   name: 'discord',
      //   icon: DcIcon,
      //   url: 'https://discord.gg/8wRECA3R',
      // },
      {
        name: 'medium',
        icon: MdIcon,
        url: 'https://medium.com/@bitop/',
      },
      {
        name: 'linktree',
        icon: LtIcon,
        url: 'https://linktr.ee/bitopexchange/',
      },
      {
        name: 'twitter',
        icon: TwIcon,
        url: 'https://twitter.com/bitop_exchange/',
      },
      {
        name: 'facebook',
        icon: FbIcon,
        url: 'https://www.facebook.com/BitopGlobal/',
      },
      // {
      //   name: 'skype',
      //   icon: SkIcon,
      //   url: 'https://join.skype.com/vZXEv7nDplgB',
      // },
    ];

    return socialList.map((social) => (
      <a
        onClick={(e) => {
          e.preventDefault();
          window.open(social.url, '_blank').focus();
        }}
        href={social.url}
        type="button"
        aria-label={`${social.name} link`}
        key={social.name}
      >
        <social.icon />
      </a>
    ));
  }, [i18n]);

  const handleRedirectTo = useCallback((e) => {
    const { dataset } = e.currentTarget;
    if (dataset.path === '/login') return PortalOpen();
    return reRouteTo(dataset.path);
  }, []);

  const getPath = useCallback(() => {
    let path = '';
    if (i18n.language === 'en') {
      path = '/en#download';
    } else if (user) {
      path = '/c2c';
    } else {
      path = '/login';
    }
    return path;
  }, [i18n, user]);

  const customerService = useCallback(() => {
    // let csUrl = 'https://chat.bitopeasy.com?eid=5de2dee8fdc42d3bd5c232c58a41bde1';
    // if (user) {
    //   const { userId, username } = user;
    //   csUrl += `&showUserId=${userId || ''}&userName=${window.encodeURIComponent(username) || '游客'}`;
    // }
    // window.open(csUrl, 'mywin', 'width=500,height=610');
    openZendesk();
  }, [user]);

  let langUrl = '';
  if (i18n.language === 'en') {
    langUrl = '/en';
  }

  function routeFunction(route) {
    switch (route) {
      case 'login':
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          reRouteTo('/login');
        } else {
          reRouteTo(`${phrase}/login/login${referral}`);
        }
        break;

      case 'announcement':
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          router.push('/news/announcement');
        } else {
          router.push('/news/announcement');
        }
        break;

      case 'helpCenter':
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          router.push('/helpCenter/beginnerGuide');
        } else {
          router.push('/helpCenter/beginnerGuide');
        }
        break;

      case 'download':
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          router.push('/download');
        } else {
          router.push('/download');
        }
        break;

      default:
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          reRouteTo('/trade/deal/BTCUSDT');
        } else {
          reRouteTo(`${phrase}${referral}`);
        }
    }
  }

  function MobileFooter() {
    return (
      <div className={styles.mobile}>
        <div className={styles.routesCon}>
          <details>
            <summary className={styles.route}>
              {t('aboutUs.title')}
            </summary>
            <div
              className={styles.subroute}
              onClick={() => routeFunction('announcement')}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction('announcement'); }}
              role="button"
              tabIndex={0}
            >
              {t('aboutUs.content.announcements')}
            </div>
            <div
              className={styles.subroute}
              onClick={() => routeFunction('helpCenter')}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction('helpCenter'); }}
              role="button"
              tabIndex={0}
            >
              {t('support.content.helpCenter')}
            </div>
            <div
              className={styles.subroute}
              onClick={() => routeFunction('download')}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction('download'); }}
              role="button"
              tabIndex={0}
            >
              {t2('newHeader.download.title')}
            </div>
          </details>
          <div className={styles.community}>
            <p className={styles.title}>{t('community.title')}</p>
            <div className={styles.socialButtons}>{renderSocialButtons}</div>
          </div>
        </div>
      </div>
    );
  }

  function DesktopFooter() {
    return (
      <div className={styles.desktop}>
        <section className={styles.companyInfo}>
          <div className={styles.aboutUs}>
            <p className={styles.title}>{t('aboutUs.title')}</p>
            {/* <p>
              <Link className={styles.linkButton} href="/aboutUs/aboutPlate">
                {t('aboutUs.content.about')}
              </Link>
            </p> */}
            <p>
              <Link className={styles.linkButton} href="/legalCenter/termsOfService">
                {t('aboutUs.content.termOfService')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/legalCenter/privacyPolicy">
                {t('aboutUs.content.privacy')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/legalCenter/riskNotice">
                {t('aboutUs.content.riskWarning')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/news/announcement">
                {t('aboutUs.content.announcements')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/news/stories">
                {t('aboutUs.content.news')}
              </Link>
            </p>
          </div>

          <div className={styles.products}>
            <p className={styles.title}>{t('products.title')}</p>
            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={() => {
                  window.location.href = (user ? '/trade/spot/BTCUSDT_CC' : `${langUrl}/trade/spotV2/BTCUSDT_CC`);
                }}
                onClick={() => {
                  window.location.href = (user ? '/trade/spot/BTCUSDT_CC' : `${langUrl}/trade/spotV2/BTCUSDT_CC`);
                }}
                tabIndex={0}
              >
                {t('products.content.spot')}
              </button>
            </p>
            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={() => {
                  window.location.href = (user ? '/trade/deal/BTCUSDT' : `${langUrl}/trade/margin/BTCUSDT`);
                }}
                onClick={() => {
                  window.location.href = (user ? '/trade/deal/BTCUSDT' : `${langUrl}/trade/margin/BTCUSDT`);
                }}
                tabIndex={0}
              >
                {t('products.content.margin')}
              </button>
            </p>
            {/* <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={() => {
                  window.location.href = (user ? '/trade/perpetual/BTCUSDT' : `${langUrl}/trade/perpetualV2/BTCUSDT`);
                }}
                onClick={() => {
                  window.location.href = (user ? '/trade/perpetual/BTCUSDT' : `${langUrl}/trade/perpetualV2/BTCUSDT`);
                }}
                tabIndex={0}
              >
                {t('products.content.perpetual')}
              </button>
            </p> */}
            {/* <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={() => {
                  window.location.href = (user ? '/trade/game/BTCUSDT' : `${langUrl}/trade/binary/BTCUSDT`);
                }}
                onClick={() => {
                  window.location.href = (user ? '/trade/game/BTCUSDT' : `${langUrl}/trade/binary/BTCUSDT`);
                }}
                tabIndex={0}
              >
                {t('products.content.binary')}
              </button>
            </p> */}
          </div>

          <div className={styles.service}>
            <p className={styles.title}>{t('service.title')}</p>
            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={handleRedirectTo}
                onClick={handleRedirectTo}
                tabIndex={0}
                data-path={getPath()}
              >
                {t('service.content.buyCrypto')}
              </button>
            </p>

            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={handleRedirectTo}
                onClick={handleRedirectTo}
                tabIndex={0}
                data-path={
                  user ? '/trade/agent/agent' : '/login'
                }
              >
                {t('service.content.referral')}
              </button>
            </p>

            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={handleRedirectTo}
                onClick={handleRedirectTo}
                tabIndex={0}
                data-path={
                  user ? '/trade/fastChange' : '/login'
                }
              >
                {t('service.content.exchange')}
              </button>
            </p>

            <p>
              <button
                type="button"
                className={styles.linkButton}
                onKeyDown={handleRedirectTo}
                onClick={handleRedirectTo}
                tabIndex={0}
                data-path="/trade/follow"
              >
                {t('service.content.copyTrading')}
              </button>
            </p>
          </div>

          <div className={styles.support}>
            <p className={styles.title}>{t('support.title')}</p>
            <p role="presentation" onKeyUp={customerService} className={styles.linkButton} onClick={customerService}>
              {t('support.content.chatSupport')}
            </p>
            {/* <p>
              <Link className={styles.linkButton} href="/trade/deal">
                {t('support.content.Fees')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/helpCenter/tradingGuide">
                {t('support.content.tradingRules')}
              </Link>
            </p> */}
            {/* <p>
              <Link className={styles.linkButton} href="/helpCenter/beginnerGuide">
                {t('support.content.beginner')}
              </Link>
            </p> */}
            <p>
              <Link className={styles.linkButton} href={`${langUrl}/helpCenter`}>
                {t('support.content.helpCenter')}
              </Link>
            </p>
            <p role="presentation" onKeyUp={customerService} className={styles.linkButton} onClick={customerService}>
              {t('support.content.feedback')}
            </p>
          </div>

          {/* <div className={styles.support}>
            <p className={styles.title}>{t('learn.title')}</p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.learnEarn')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.cryptoPrices')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.bitcoinPrice')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.ethereumPrice')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.cryptoPricePredictions')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.bitcoinPricePrediction')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.ethereumPricePrediction')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyBNB')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyBUSD')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyBitcoin')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyEthereum')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyDogeCoin')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyRipple')}
              </Link>
            </p>
            <p>
              <Link className={styles.linkButton} href="/trade/spot">
                {t('learn.content.buyTradableAltcoins')}
              </Link>
            </p>
          </div> */}

          <div className={styles.community}>
            <p className={styles.title}>{t('community.title')}</p>
            <div className={styles.socialButtons}>{renderSocialButtons}</div>
          </div>
        </section>
      </div>
    );
  }

  return (
    <footer className={styles.container}>
      <article className={styles.content}>
        <article className={styles.logosContent}>
          <LogoMarquee />
        </article>
        <DesktopFooter />
        <MobileFooter />
        <hr className={styles.divider} />
        <section className={styles.copyright}>
          {t('copyright')}
        </section>
      </article>
      <Portal>
        <ServicePlatformChoice cross closeFunc={PortalClose} width="10%" />
      </Portal>
    </footer>
  );
}

export default Footer;
